import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import PageHelmet from 'components/Helmets/PageHelmet';
import { TextColumn } from 'style/components';
import { HTMLContent } from '../components/Content';

export const MarkdownPageTemplate = ({ content, helmet }) => (
  <section>
    {helmet}
    <TextColumn>
      <HTMLContent content={content} />
    </TextColumn>
  </section>
);

MarkdownPageTemplate.propTypes = {
  content: PropTypes.string,
  helmet: PropTypes.node,
};

MarkdownPageTemplate.defaultProps = {
  content: ``,
  helmet: null,
};

const MarkdownPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <MarkdownPageTemplate
      ContentComponent={HTMLContent}
      title={post.frontmatter.title}
      content={post.html}
      helmet={(
        <PageHelmet
          pageTitle={post.frontmatter.title}
          path={post.fields.slug}
        />
      )}
    />
  );
};

MarkdownPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MarkdownPage;

export const MarkdownPageQuery = graphql`
  query MarkdownPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        path
      }
    }
  }
`;
